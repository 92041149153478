import React from "react";

import { AutobizAvatarProps } from "./AutobizAvatar.types";

import { Avatar, AvatarImage } from "./AutobizAvatar.styles";

const avatarDefaultSvg = (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
  </svg>
);

const AutobizAvatar: React.FC<AutobizAvatarProps> = ({ image }) => (
  <Avatar>
    <AvatarImage>
      {image ? <img src={image} alt="" /> : avatarDefaultSvg}
    </AvatarImage>
  </Avatar>
);

export default AutobizAvatar;
