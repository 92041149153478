import styled from "styled-components";

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0;
`;

export const AvatarImage = styled.div`
  display: flex;
  overflow: hidden;
  margin-right: 5px;
  border-radius: 50%;
  max-width: 50px;
  max-height: 50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  svg {
    height: 35px;
    width: 35px;
    background-color: #ccc;
    fill: #fff;
  }
`;
