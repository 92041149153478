import { Translation } from "./AutobizNavBar.types";

export const translation: Translation = {
  en: {
    logout: "Logout",
    my_apps: "My apps",
    help: "Help",
  },
  fr: {
    logout: "Se déconnecter",
    my_apps: "Mes Apps",
    help: "Aide",
  },
  es: {
    logout: "Desconexión",
    my_apps: "Mis apps",
    help: "Ayuda",
  },
  pt: {
    logout: "Sair",
    my_apps: "Meus apps",
    help: "Apoio",
  },
  it: {
    logout: "Logout",
    my_apps: "Le mie apps",
    help: "Aiuto",
  },
  de: {
    logout: "Abmelden",
    my_apps: "Meine apps",
    help: "Hilfe",
  },
  nl: {
    logout: "Logout",
    my_apps: "Mijn apps",
    help: "Help",
  },
};
