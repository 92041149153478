import React from "react";
import { StyledAutobizButton } from "./AutobizButton.styles";
import { AutobizButtonProps } from "./AutobizButton.types";

const Button: React.FC<AutobizButtonProps> = (props) => (
  <StyledAutobizButton {...props}>
    {props.children && <span>{props.children}</span>}
    {props.chevron && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="11"
        viewBox="0 0 6 11"
      >
        <path
          d="M4910.369,364.137l4.7-4.7,4.7,4.7"
          transform="translate(364.844 -4909.662) rotate(90)"
        />
      </svg>
    )}
  </StyledAutobizButton>
);

export default Button;
