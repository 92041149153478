import styled from "styled-components";

export const BannerWrapper = styled.div`
  display: block;
  margin: 1em 0;
`;

export const Banner = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "black"};
  color: ${({ textColor }) => (textColor ? textColor : "black")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  padding: 1em;
`;
