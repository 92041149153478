import { Theme } from "./theme.types";

const defaultTheme: Theme = {
  name: "default",
  colors: {
    primary: "#FF4E00",
    secondary: "#151515",
    grayLight: "#f7f7f7",
    grayNormal: "#BEBCBC",
    grayDark: "#AAA9A9",
  },
  buttons: {
    primary: {
      default: {
        color: "#FFFFFF",
        borderColor: "#FF4E00",
        backgroundColor: "#FF4E00",
      },
      hover: {
        color: "#FFFFFF",
        borderColor: "#ff9566",
        backgroundColor: "#ff9566",
      },
      disabled: {
        color: "#151515",
        borderColor: "#BEBCBC",
        backgroundColor: "#BEBCBC",
      },
    },
    secondary: {
      default: {
        color: "#000000",
        borderColor: "#000000",
        backgroundColor: "#FFFFFF",
      },
      hover: {
        color: "#FF4E00",
        borderColor: "#FF4E00",
        backgroundColor: "#ff9566",
      },
      disabled: {
        color: "#000000",
        borderColor: "#BEBCBC",
        backgroundColor: "#BEBCBC",
      },
    },
    tertiary: {
      default: {
        color: "#FF4E00",
        borderColor: "#FF4E00",
        backgroundColor: "#FFFFFF",
      },
      hover: {
        color: "#FFFFFF",
        borderColor: "#FF4E00",
        backgroundColor: "#ff9566",
      },
      disabled: {
        color: "#151515",
        borderColor: "#BEBCBC",
        backgroundColor: "#BEBCBC",
      },
    },
    borderWidth: "2px",
  },
};

export default defaultTheme;
