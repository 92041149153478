import styled from "styled-components";
export const PopupWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease, visibility 0s 0.25s;

  &.open {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
  &.closed {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s 0.25s;
  }
`;

export const Popup = styled.div`
  background-color: #fff;
  width: ${({ width }) => (width ? width : "800px")};
  border: 1px solid #ccc;
  margin: 0 auto; /* Supprimer le margin top */
  max-width: calc(100% - 60px);
  max-height: 90vh; /* Ajout d'une limite de hauteur */
  padding: 20px;
  overflow-y: auto; /* Ajout de la barre de défilement verticale */
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.25s ease, transform 0.25s ease;

  &.open {
    opacity: 1;
    transform: scale(1);
  }
  &.closed {
    opacity: 0;
    transform: scale(0.95);
  }
`;

export const PopupHeader = styled.div`
  font-size: 2em;
  margin-bottom: 30px;
  text-align: center;
`;

export const PopupFooter = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  /* Media query pour les écrans plus petits */
  @media (max-width: 600px) {
    gap: 10px;
    flex-direction: column;
    div:first-child {
      order: 2; /* Le premier div dans le DOM apparaîtra en deuxième */
    }
    div:last-child {
      order: 1; /* Le deuxième div dans le DOM apparaîtra en premier */
    }
  }
  div {
    flex: 1;
  }
  button {
    cursor: pointer;
    word-break: break-word;
  }
`;
