// Generated with util/create-icon.js from @styled-icons/ionicons-outline/Close

import * as React from "react";
import { SvgProps } from "../AutobizIcon.types";

export const Close = ({ size, color }: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    height={size}
    width={size}
    aria-hidden="true"
    focusable="false"
    fill={color ? color : "currentColor"}
    style={{
      display: "inline-block",
      verticalAlign: "middle",
      overflow: "hidden",
    }}
  >
    <path fill={color ? color : "currentColor"} stroke={color ? color : "currentColor"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144m224 0L144 368" ></path>
  </svg>
);
