import React from "react";

import { AutobizIconProps } from "./AutobizIcon.types";

import {
  Bell,
  Calculator,
  CalendarAlt,
  CalendarCheck,
  Car,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  ChartArea,
  ChevronDown,
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
  ChevronUp,
  ChevronUpDown,
  CashRegister,
  Cog,
  Cloud,
  Close,
  Download,
  Edit,
  EuroSign,
  EuroSignCircle,
  FilePdf,
  Filter,
  Grid,
  Glance,
  InfoCircle,
  Link,
  MenuCheck,
  MinusCircle,
  MoneyBillWave,
  PhoneSquareAlt,
  PiggyBank,
  Plus,
  PlusCircle,
  QuestionCircle,
  Rss,
  Search,
  ShoppingCart,
  Signal,
  Star,
  StarEmpty,
  StatsChart,
  SyncAlt,
  Times,
  TimesCircle,
  Tools,
  Trash,
  Upload,
  User,
  UserPlus,
  Warning,
} from "./Icons";

const icons = {
  Bell,
  Calculator,
  CalendarAlt,
  CalendarCheck,
  Car,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  ChartArea,
  ChevronDown,
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
  ChevronUp,
  ChevronUpDown,
  CashRegister,
  Cog,
  Cloud,
  Close,
  Download,
  Edit,
  EuroSign,
  EuroSignCircle,
  FilePdf,
  Filter,
  Glance,
  Grid,
  InfoCircle,
  Link,
  MenuCheck,
  MinusCircle,
  MoneyBillWave,
  PhoneSquareAlt,
  PiggyBank,
  Plus,
  PlusCircle,
  QuestionCircle,
  Rss,
  Search,
  ShoppingCart,
  Signal,
  Star,
  StarEmpty,
  StatsChart,
  SyncAlt,
  Times,
  TimesCircle,
  Tools,
  Trash,
  Upload,
  User,
  UserPlus,
  Warning,
};

const AutobizIcon: React.FC<AutobizIconProps> = ({ name, size, color }) => {
  const SpecificIcon = icons[name];
  const iconAttributes = {};
  if (color) {
    iconAttributes["color"] = color;
  }

  return <SpecificIcon size={size} {...iconAttributes} />;
};

export default AutobizIcon;
