// Generated with util/create-icon.js from @styled-icons/bootstrap/CalendarCheck

import * as React from "react";
import { SvgProps } from "../AutobizIcon.types";

export const CalendarCheck = ({ size, color }: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    height={size}
    width={size}
    aria-hidden="true"
    focusable="false"
    fill={color ? color : "currentColor"}
    style={{
      display: "inline-block",
      verticalAlign: "middle",
      overflow: "hidden",
    }}
  >
    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"></path>
  </svg>
);
