import styled from "styled-components";

export const InitialWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0;
`;

export const Initial = styled.div`
  display: block;
  overflow: hidden;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border: 1px solid white;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "black")};
`;
