// Generated with util/create-icon.js from @styled-icons/fa-solid/EuroSign

import * as React from "react";
import { SvgProps } from "../AutobizIcon.types";

export const MenuCheck = ({ size, color }: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 30.122 20.808"
    aria-hidden="true"
    focusable="false"
    fill={color ? color : "currentColor"}
    style={{
      display: "inline-block",
      verticalAlign: "middle",
      overflow: "hidden",
    }}
  >
    <g transform="translate(-4.5 -9)">
      <path
        d="M24.379,9H4.5v3.313H24.379Z"
        transform="translate(0 0)"
        fill={color ? color : "currentColor"}
      />
      <path
        d="M24.379,15H4.5v3.313H24.379Z"
        transform="translate(0 0.626)"
        fill={color ? color : "currentColor"}
      />
      <path
        d="M4.5,21H17.752v3.313H4.5Z"
        transform="translate(0 1.252)"
        fill={color ? color : "currentColor"}
      />
      <path
        d="M17.985,23.091l2.345-2.342,3.513,3.513,7.029-7.027,2.342,2.342-9.372,9.372-5.856-5.858Z"
        transform="translate(1.407 0.859)"
        fill={color ? color : "currentColor"}
      />
    </g>
  </svg>
);
