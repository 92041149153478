import { createGlobalStyle } from "styled-components";

import FacitLightWoff from "../../assets/fonts/facit-light.woff";
import FacitRegularWoff from "../../assets/fonts/facit-regular.woff";
import FacitSemiBoldWoff from "../../assets/fonts/facit-semibold.woff";
import FacitBoldWoff from "../../assets/fonts/facit-bold.woff";

export const GlobalStyle = createGlobalStyle`
body {

  @font-face {
    font-family: 'Facit';
    src: url(${FacitLightWoff}) format('woff');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Facit';
    src: url(${FacitRegularWoff}) format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Facit';
    src: url(${FacitSemiBoldWoff}) format('woff');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Facit';
    src: url(${FacitBoldWoff}) format('woff');
    font-weight: 700;
  }

  min-width: 320px;
  //background-color: red;

  font-family: Facit;
}
`;
