import React from "react";

import { AutobizInitialProps } from "./AutobizInitial.types";

import { InitialWrapper, Initial } from "./AutobizInitial.styles";

const stringToColour = function (str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

const AutobizAvatar: React.FC<AutobizInitialProps> = ({
  first,
  second,
  uniqueColor,
}) => {
  const firstInitial = first?.substring(0, 1) || "";
  const secondInitial = second?.substring(0, 1) || "";
  const initials = firstInitial + secondInitial;

  const bgColor = uniqueColor ? stringToColour(first + second) : "#ccc";
  return (
    <InitialWrapper>
      <Initial bgColor={bgColor}>{initials}</Initial>
    </InitialWrapper>
  );
};

export default AutobizAvatar;
