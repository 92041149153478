import styled from "styled-components";
export const StyledAutobizFormGroup = styled.div`
  margin-bottom: 1rem;
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  select,
  input {
    font-weight: 100;
    display: block;
    width: 100%;
    padding: 0.375rem;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bebcbc;
    &:disabled {
      background: #e7e7e7;
    }
  }
`;
