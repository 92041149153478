// Generated with util/create-icon.js from @styled-icons/fa-solid/CaretLeft

import * as React from "react";
import { SvgProps } from "../AutobizIcon.types";

export const CaretLeft = ({ size, color }: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 192 512"
    height={size}
    width={size}
    aria-hidden="true"
    focusable="false"
    fill={color ? color : "currentColor"}
    style={{
      display: "inline-block",
      verticalAlign: "middle",
      overflow: "hidden",
    }}
  >
    <path fill={color ? color : "currentColor"} d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" ></path>
  </svg>
);
