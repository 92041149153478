// Generated with util/create-component.js
import styled from "styled-components";
export const StyledAutobizLogo = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || "column"};
  svg {
    display: block;
  }
  align-items: center;
`;

export const LogoBrand = styled.div`
  width: ${({ size }) => (size === "sm" ? "34px" : "50px")};
  height: ${({ size }) => (size === "sm" ? "34px" : "50px")};
  svg {
    width: ${({ size }) => (size === "sm" ? "34px" : "50px")};
    height: ${({ size }) => (size === "sm" ? "34px" : "50px")};
  }
`;

export const LogoBrandName = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin: 8px;
  @media (max-width: 767px) {
    display: ${({ withBrandNameOnMobile }) =>
      withBrandNameOnMobile ? "inherit" : "none"};
  }
  @media (min-width: 768px) {
    display: ${({ withBrandNameOnDesktop }) =>
      withBrandNameOnDesktop ? "inherit" : "none"};
  }
`;

export const BrandColor = styled.span`
  color: ${({ color }) => color};
`;
