import styled from "styled-components";

export const StyledAutobizNavBar = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #ccc;
  height: 50px;
  font-size: 13px;
  z-index: 1000;
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  color: black;
  text-align: left;
`;

export const Container = styled.div`
  min-width: 320px;
  max-width: ${({ maxWidth }) => `${maxWidth}px` || "100%"};
  display: flex;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  align-items: center;
`;

export const NavBarBrand = styled.a`
  margin-right: auto;
  padding-left: 7.5px;
  display: block;
  text-decoration: none;
  color: white;
  display: flex;
  font-weight: 700;
  font-size: 20px;
  svg {
    height: 34px;
    margin-right: 5px;
  }
  &:hover {
    text-decoration: none !important;
  }
`;

export const NavBarItem = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  &:after {
    content: "";
    width: 1px;
    background-color: ${({ color }) => color || "black"};
    height: 25px;
  }

  &:last-child:after {
    display: none;
  }
  svg {
    color: ${({ color }) => color || "black"};
  }
  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    &.open {
      background-color: ${({ buttonColorSelected }) =>
        buttonColorSelected || "#ccc"};
    }
    padding: 0 7.5px;
    height: 100%;
    border: 0;
    cursor: ${({ cursor }) => cursor || "pointer"};
    color: ${({ color }) => color || "black"};
  }
`;

export const NavBarItemLabel = styled.span`
  display: none;
  @media (min-width: 768px) {
    margin-left: 3px;
    display: inline-block;
  }
`;

export const NavBarItemContent = styled.div`
  background-color: #eff3ff;
  position: fixed;
  top: ${({ fixedTop }) => (fixedTop ? "0px" : "60px")};
  right: ${({ fixedTop }) => (fixedTop ? "0px" : "15px")};
  width: 100%;
  max-width: 280px;
  box-shadow: -6px 6px 5px #00000029;
  ul {
    display: block;
    max-width: 300px;

    list-style-type: none;
    padding: 0;
    margin: auto;
    li {
      padding: 10px;
      margin: 0;
      border-bottom: 1px solid #bebcbc;
      &.isLink {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
      p {
        margin: 0 0 10px 0;
      }
    }
  }
  @media (min-width: 768px) {
    position: absolute;
    top: 60px;
    width: 300px;
    right: 7.5px;
  }

  a,
  a:not([href]):not([tabindex]),
  a:not([href]):not([tabindex]):focus,
  a:not([href]):not([tabindex]):hover {
    color: #ff4e00 !important;
    text-decoration: underline;
  }
  hr {
    border-bottom: 1px solid #bebcbc;
    border-top: 0;
    margin: 0;
  }
`;

export const NavBarTriangle = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  top: 46px;
  &:before {
    content: "";
    width: 0px;
    position: absolute;
    height: 0px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #eff3ff;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export const AppButtonContainer = styled.div`
  float: left;
  padding: 10px;
  text-align: left;
`;

export const AppButton = styled.div`
  padding: 5px 0;
  margin: 5px;
  display: inline-block;
  background-color: white;
  color: black;
  cursor: pointer;
  width: 76px;
  div {
    margin-bottom: 5px;
    text-align: center;
  }
`;

export const UserContainer = styled.div`
  display: flex;
`;

export const UserImage = styled.div`
  margin-right: 10px;
`;

export const LogoutLink = styled.a`
  color: #ff4e00;
  text-decoration: underline;
  cursor: pointer;
`;

export const NotificationBubble = styled.div`
  color: #fff;
  border-radius: 10px;
  background-color: #c6041b;
  position: absolute;
  top: 29px;
  left: 12px;
  padding: 2px 4px;
  font-size: 10px;
  line-height: 1;
`;
