// Generated with util/create-component.js
import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./GlobalStyle";
import { AutobizThemeProps } from "./AutobizTheme.types";

const Theme: React.FC<AutobizThemeProps> = ({ theme, children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

export default Theme;
