import styled from "styled-components";

export const StyledAutobizNavTabs = styled.nav`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayDark};
`;

export const StyledAutobizNavLink = styled.button`
  margin-bottom: -1px;
  background: transparent;
  outline: 0;
  font-size: 16px;
  padding: 15px 30px;
  border-top: ${(props) =>
    props.active
      ? `1px solid ${props.theme.colors.grayDark}`
      : "1px solid transparent"};
  border-right: ${(props) =>
    props.active
      ? `1px solid ${props.theme.colors.grayDark}`
      : "1px solid transparent"};
  border-bottom: ${(props) =>
    props.active ? `1px solid #fff` : "1px solid transparent"};
  border-left: ${(props) =>
    props.active
      ? `1px solid ${props.theme.colors.grayDark}`
      : "1px solid transparent"};
  font-weight: ${(props) => (props.active ? `700` : "400")};
`;

export const StyledAutobizTabContent = styled.div``;

export const StyledAutobizTabPane = styled.div``;
