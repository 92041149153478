import styled from "styled-components";
export const StyledAutobizButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  width: ${(props) => (props.block ? "100%" : "auto")};
  border-width: ${(props) => props.theme.buttons.borderWidth || "1px"};
  border-style: solid;
  color: ${(props) => props.theme.buttons[props.color].default.color || "XXX"};
  background-color: ${(props) =>
    props.theme.buttons[props.color].default.backgroundColor || "XXX"};
  border-color: ${(props) =>
    props.theme.buttons[props.color].default.borderColor || "XXX"};

  ${({ chevron }) =>
    chevron &&
    `
    justify-content: left;
    span{
      margin-right:1rem
    }
  `}
  span {
    display: flex;
    align-items: center;
  }
  svg {
    fill: none;
    stroke: ${(props) =>
      props.theme.buttons[props.color].default.color || "XXX"};
    stroke-width: 2px;
    margin: 0 5px 0 0;

    ${({ chevron }) =>
      chevron &&
      `
      margin-left:auto
    `}
  }

  &:hover {
    color: ${(props) => props.theme.buttons[props.color].hover.color || "XXX"};
    background-color: ${(props) =>
      props.theme.buttons[props.color].hover.backgroundColor || "XXX"};
    border-color: ${(props) =>
      props.theme.buttons[props.color].hover.borderColor || "XXX"};
    svg {
      stroke: ${(props) =>
        props.theme.buttons[props.color].hover.color || "XXX"};
    }
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${(props) =>
      props.theme.buttons[props.color].disabled.backgroundColor || "XXX"};
    border-color: ${(props) =>
      props.theme.buttons[props.color].disabled.borderColor || "XXX"};
    color: ${(props) =>
      props.theme.buttons[props.color].disabled.color || "XXX"};
    svg {
      stroke: ${(props) =>
        props.theme.buttons[props.color].disabled.color || "XXX"};
    }
  }
`;
