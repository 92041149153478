import React from "react";
import { StyledAutobizSectionTitle } from "./AutobizSectionTitle.styles";

import { AutobizSectionTitleProps } from "./AutobizSectionTitle.types";

const AutobizSectionTitle: React.FC<AutobizSectionTitleProps> = (props) => (
  <StyledAutobizSectionTitle {...props}>
    {props.children}
  </StyledAutobizSectionTitle>
);

export default AutobizSectionTitle;
