import { AvailableApps, AvailableLangs } from "../types";

export const translateBrandName = (
  brandName: AvailableApps,
  language: AvailableLangs,
  country: string,
  short?: boolean
): string => {
  let out = "";

  if (short) {
    out = "";
  } else {
    out = "autobiz";
  }

  switch (brandName) {
    case "autobizOffice":
      out += "Office";
      break;

    case "autobizCarcheck":
      out += "Carcheck";
      break;

    case "autobizMarket_v2":
    case "autobizMarket":
      switch (country) {
        case "es":
        case "ES":
          out += "SistemaVO";
          break;
        default:
          out += "Market";
          break;
      }
      break;

    case "autobizTrade":
      out += "Trade";
      break;

    case "autobizAPI":
      out += "API";
      break;

    case "autobizAcademy":
      out += "Academy";
      break;

    case "autobizIframe":
      out += "iFrame";
      break;

    case "autobizClic2Sell":
      out += "Clic2Sell";
      break;

    case "autobizMonStock":
      switch (language) {
        case "fr":
          out += "MonStock";
          break;
        case "es":
          out += "MiStock";
          break;
        case "pt":
          out += "MeuStock";
          break;
        default:
          out += "MyStock";
          break;
      }
      break;

    case "autobizConseil":
      out += "Conseil";
      break;

    case "autobizAi":
      out += "Ai";
      break;

    case "autobizClic2Buy":
      out += "Clic2Buy";
      break;

    case "autobizAdsScan":
      out += "AdsScan";
      break;

    case "autobizLeads":
      out += "Leads";
      break;
    case "autobizForecast":
      out += "Forecast";
      break;
    case "autobizPricing":
      out += "Pricing";
      break;
    default:
      out = "default";
      break;
  }

  return out;
};
