import styled from "styled-components";
export const StyledAutobizBox = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayDark};
  margin-bottom: 25px;
`;

export const StyledAutobizBoxHead = styled.div`
  padding: 15px 20px;
  background-color: ${(props) => props.theme.colors.grayLight};
  border-bottom: 1px solid ${(props) => props.theme.colors.grayDark};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledAutobizBoxTitle = styled.div`
  font-size: 21px;
  font-weight: 300;
  line-height: 1;
`;

export const StyledAutobizBoxActions = styled.div``;

export const StyledAutobizBoxBody = styled.div`
  background-color: #fff;
  padding: 15px 20px;
`;
