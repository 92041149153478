import styled from "styled-components";
export const StyledAutobizSectionTitle = styled.h2`
  display: flex;
  font-size: 1.3125rem;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  border-bottom: 3px solid #000;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
`;
