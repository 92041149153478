import React from "react";

import AutobizIcon from "../AutobizIcon";
import { AutobizNavProps, AutobizNavState } from "./AutobizNavBar.types";

import {
  StyledAutobizNavBar,
  Container,
  NavBarBrand,
  NavBarItem,
  NavBarItemContent,
  NavBarItemLabel,
  AppButton,
  AppButtonContainer,
  NavBarTriangle,
  UserContainer,
  UserImage,
  LogoutLink,
  NotificationBubble,
} from "./AutobizNavBar.styles";
import { translation } from "./AutobizNavBar.locale";
import { colorBrands } from "../../themes/colorBrands";
import AutobizLogo from "../AutobizLogo/AutobizLogo";
import AutobizAvatar from "../AutobizAvatar/AutobizAvatar";
import AutobizInitial from "../AutobizInitial";
import { translateBrandName } from "../../helpers/translateBrandName";
class AutobizNavbar extends React.Component<AutobizNavProps, AutobizNavState> {
  constructor(props) {
    super(props);
    this.state = {
      openedItem: "",
    };
  }

  setItemOpen = (value: string) => {
    this.setState({ openedItem: value });
  };
  render() {
    const { menu, maxWidth, brandLink, lang, brand } = this.props;

    const headerProps = colorBrands[brand];

    const navBarAttributes = {};

    if (brandLink) {
      navBarAttributes["href"] = brandLink;
    }

    return (
      <StyledAutobizNavBar backgroundColor={headerProps.header.backgroundColor}>
        <Container maxWidth={maxWidth}>
          <NavBarBrand {...navBarAttributes}>
            <AutobizLogo
              lang={lang}
              country={menu?.user?.country}
              product={brand}
              direction="row"
              withWhiteBrandName
              withBrandNameOnMobile={false}
              withBrandNameOnDesktop={true}
            />
          </NavBarBrand>

          {menu?.help && (
            <NavBarItem
              color={headerProps.header.color}
              buttonColorSelected={headerProps.header.buttonColorSelected}
            >
              <button
                role="button"
                className={this.state.openedItem === "help" ? "open" : ""}
                onClick={() =>
                  this.setItemOpen(
                    this.state.openedItem === "help" ? "" : "help"
                  )
                }
              >
                <AutobizIcon name="QuestionCircle" size={18} />
                <NavBarItemLabel>{translation[lang].help}</NavBarItemLabel>
              </button>
              {this.state.openedItem === "help" && (
                <>
                  <NavBarTriangle />
                  <NavBarItemContent>
                    <ul>
                      {menu.help.map((i, k) => (
                        <li className="isLink" key={k} onClick={i.onClick}>
                          {i.name}
                        </li>
                      ))}
                    </ul>
                  </NavBarItemContent>
                </>
              )}
            </NavBarItem>
          )}

          {menu?.parameters && (
            <NavBarItem
              color={headerProps.header.color}
              buttonColorSelected={headerProps.header.buttonColorSelected}
            >
              <button
                className={this.state.openedItem === "parameters" ? "open" : ""}
                onClick={() =>
                  this.setItemOpen(
                    this.state.openedItem === "parameters" ? "" : "parameters"
                  )
                }
              >
                <AutobizIcon name="Cog" size={18} />
              </button>
              {this.state.openedItem === "parameters" && (
                <>
                  <NavBarTriangle />
                  <NavBarItemContent>
                    <ul>
                      {menu.parameters.map((i, k) => (
                        <li className="isLink" key={k} onClick={i.onClick}>
                          {i.name}
                        </li>
                      ))}
                    </ul>
                  </NavBarItemContent>
                </>
              )}
            </NavBarItem>
          )}

          {menu?.notifications && (
            <NavBarItem
              color={headerProps.header.color}
              buttonColorSelected={headerProps.header.buttonColorSelected}
              cursor={menu.notifications.url ? "pointer" : "default"}
            >
              <button
                onClick={() =>
                  menu.notifications.url && menu.notifications.newTab
                    ? window.open(
                        menu.notifications.url,
                        menu.notifications.newTab ? "_blank" : "_self"
                      )
                    : null
                }
              >
                <AutobizIcon name="Bell" size={18} />
                {menu.notifications.count > 0 && (
                  <NotificationBubble>
                    {menu.notifications.count}
                  </NotificationBubble>
                )}
                {menu.notifications.count > 100 && (
                  <NotificationBubble> 99+</NotificationBubble>
                )}
              </button>
            </NavBarItem>
          )}

          {menu?.apps && menu?.apps.length > 0 && (
            <NavBarItem
              color={headerProps.header.color}
              buttonColorSelected={headerProps.header.buttonColorSelected}
            >
              <button
                role="button"
                className={this.state.openedItem === "apps" ? "open" : ""}
                onClick={() =>
                  this.setItemOpen(
                    this.state.openedItem === "apps" ? "" : "apps"
                  )
                }
              >
                <AutobizIcon name="Grid" size={28} />
                <NavBarItemLabel>{translation[lang].my_apps}</NavBarItemLabel>
              </button>
              {this.state.openedItem === "apps" && (
                <>
                  <NavBarTriangle />
                  <NavBarItemContent>
                    <AppButtonContainer>
                      {menu.apps.map((i, k) => (
                        <AppButton
                          key={k}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(i.link, "_blank")?.focus();
                          }}
                        >
                          <AutobizLogo
                            lang={lang}
                            country={menu?.user?.country}
                            product={i.name}
                            withBrandNameOnMobile={false}
                            withBrandNameOnDesktop={false}
                          />
                          <div>
                            {translateBrandName(
                              i.name,
                              lang,
                              menu?.user?.country,
                              true
                            )}{" "}
                            {i.name === "autobizMarket_v2" ? "v2" : ""}
                          </div>
                        </AppButton>
                      ))}
                    </AppButtonContainer>
                  </NavBarItemContent>
                </>
              )}
            </NavBarItem>
          )}

          {menu?.user && (
            <NavBarItem
              color={headerProps.header.color}
              buttonColorSelected={headerProps.header.buttonColorSelected}
            >
              <button
                role="button"
                className={this.state.openedItem === "user" ? "open" : ""}
                onClick={() =>
                  this.setItemOpen(
                    this.state.openedItem === "user" ? "" : "user"
                  )
                }
              >
                {menu.user.picture ? (
                  <AutobizAvatar image={menu.user.picture} />
                ) : (
                  <AutobizInitial
                    first={menu.user.firstName}
                    second={menu.user.lastName}
                  />
                )}

                <AutobizIcon name="CaretDown" size={15} />
              </button>
              {this.state.openedItem === "user" && (
                <>
                  <NavBarTriangle />
                  <NavBarItemContent>
                    <ul>
                      <li>
                        <UserContainer>
                          <UserImage>
                            {menu.user.picture ? (
                              <AutobizAvatar image={menu.user.picture} />
                            ) : (
                              <AutobizInitial
                                first={menu.user.firstName}
                                second={menu.user.lastName}
                              />
                            )}
                          </UserImage>
                          <div>
                            <strong>
                              {menu.user.firstName} {menu.user.lastName}{" "}
                              {menu.user.country && <>({menu.user.country})</>}
                            </strong>
                            <br />
                            {menu.user.email}
                            <br />
                            {menu.user.company}
                          </div>
                        </UserContainer>
                      </li>
                    </ul>
                    {menu.userMenu && (
                      <>
                        <ul>
                          {menu.userMenu.map((i, k) => (
                            <li className="isLink" key={k} onClick={i.onClick}>
                              {i.name}
                            </li>
                          ))}
                        </ul>
                        <hr />
                      </>
                    )}

                    {menu.languages && (
                      <ul>
                        {menu.languages.map((i, k) => (
                          <li className="isLink" key={k} onClick={i.onClick}>
                            {i.name}
                          </li>
                        ))}
                      </ul>
                    )}
                    <hr />
                    <ul>
                      <li>
                        <LogoutLink onClick={menu.user.logout}>
                          {translation[lang].logout}
                        </LogoutLink>
                      </li>
                    </ul>
                  </NavBarItemContent>
                </>
              )}
            </NavBarItem>
          )}
        </Container>
      </StyledAutobizNavBar>
    );
  }
}

export default AutobizNavbar;
